import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom' ;

import { invalidEmail, invalidMobile, isBlank, invalidName } from '../valid.js' ;
import Dropdown from '../dropdown/Dropdown.js' ;
import Loading from '../loading/Loading.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import Text from '../text/Text.js' ;
import TextArea from '../text/TextArea.js' ;
import './buildpage.css' ;
import '../categoryPage/categorypage.css' ;

import tick from '../images/tick.webp';
import blank from '../images/blank.webp';
import bpc from '../images/bpc.webp';

const initObj = {
	RAM: 'Crucial 4GB RAM',
	HardDisk: 'Western Digital 1TB Internal HDD',
	SSD: 'Crucial 120GB SSD Memory',
	MotherBoard: 'GigaByte H310 (For Intel 9th gen)',
	Processor: 'Intel i3-9th Gen',
	GPU: 'Nvidia GeForce GT710',
	Display: 'HP 1366x768 Monitor',
	Cabinet: 'I-ball (w/ 450W SMPS)',
	UPS: 'Intex 725',
	OS: 'Microsoft Windows 10'
} ;

const checkObj = {
	Display: false,
	UPS: false,
	SSD: false,
	GPU: false
} ;

const returnImage = (bool) => {
	if(bool)
		return <img src={tick} alt="tick" />
	else
		return <img src={blank} alt="blank" />
}

const returnString = (obj) => obj.brand + ' ' + obj.name ;

// obj2 check ke liye hai aur obj1 initial value ke liye hai
const BuildPage = ({type, obj1, obj2 }) => {
	const [data, setData] = useState(null) ;
	const [price, setPrice] = useState(null) ;
	const [check, setCheck] = useState(obj2?obj2:checkObj) ;
	const [selected, setSelected] = useState(obj1?obj1:initObj) ;
	const [error, setError] = useState('') ;
	const [mode, setMode] = useState('normal') ;
	const [name, setName] = useState('') ;
	const [address, setAddress] = useState('') ;
	const [mobile, setMobile] = useState('') ;
	const [email, setEmail] = useState('') ;

	useEffect(() =>{
		fetch('https://myarth-api.herokuapp.com/parts')
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then(resp => setData(resp)) 
		.catch( err  => console.log(err)) ;
		
	},[]) ;

	const onOptionChange = (event) => {
		setSelected({...selected, [event.target.name]: event.target.value}) ;
		setPrice(null) ;
	}

	const toggleCheck = (str) => {
		setCheck({...check, [str]: !(check[str]) }) ;
	}

	const onEstimateClick = () => {
		fetch('https://myarth-api.herokuapp.com/estimate',{
			method : 'post' ,
			headers : { 'Content-Type' : 'application/json'} ,
			body :JSON.stringify({ selected, check }) ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then(data => setPrice(data))  
		.catch( err  =>	console.log(err) ) ;
	}

	const checkData = () => {
		if(data)
			return Object.keys(data).filter(one => !(check[one])).map((one,i) =>{
							return (
								<div className="build-sub" key={i}>
									<Dropdown label={one} name={one} 
											value={selected[one]} onChange={onOptionChange}
											options={data[one].map(item => returnString(item))} />
								</div>
							) ; 
						})
		else 
			return <Loading /> ;
	}

	const returnSelected = () => {
		return Object.keys(selected).filter(one => !(check[one])).map((one,i) => <p className="category-specs" key={i}><strong>{one} </strong>: {selected[one]}</p>) ;
	}

	const onSendClick = () => {
		if(error !== '')
			setError('You cannot proceed without fixing all the errors');
		else if(invalidName(name))
			setError(invalidName(name));
	  	else if(invalidEmail(email))
			setError(invalidEmail(email));
		else if(invalidMobile(mobile))
			setError(invalidMobile(mobile));
	  	else if(isBlank(address, 'Address'))
			setError(isBlank(address, 'Address'));
		else
		{	//console.log(price, selected, check,  name, email, mobile, address) ;

			fetch('https://myarth-api.herokuapp.com/order',{
				method : 'post' ,
				headers : { 'Content-Type' : 'application/json'} ,
				body : JSON.stringify({ name, mobile, email, address, selected, check, price }) ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				else
					throw Error(res.statusText) ;
			})
			.then(data => {	
				setName('') ;
				setMobile('') ; 
				setEmail('') ;
				setAddress('') ;
				setMode('success') ;
				// addNotif('Successfully Sent Message/Feedback', 'success') ;
			}) 
			.catch( err  => {
				console.log(err) ; 
				setMode('error') ;
				// addNotif('There was a problem with sending message', 'error') ;
			}) ;
		}
	}

	if(mode === 'normal')
	{	return (
			<div className="buildpage"> 
				<h3 className="build-title">{type?"Configure your PC":"Customise Your Own PC"}</h3>
				<div className="build-flex">
					<div className="build-left">
						{	checkData()	} 
					</div>
					<div className="build-right">
						<img src={bpc} alt="build pc" className="bpc"/>
						<h4> I do not require: </h4>
						<div className="build-right-option">
							<p onClick={()=> toggleCheck('Display')}> 
								{returnImage(check.Display)}&ensp; Monitor </p>
							<p onClick={()=> toggleCheck('UPS')}> 
								{returnImage(check.UPS)}&ensp; UPS </p> 
							<p onClick={()=> toggleCheck('SSD')}> 
								{returnImage(check.SSD)}&ensp; SSD </p>
							<p onClick={()=> toggleCheck('GPU')}> 
								{returnImage(check.GPU)}&ensp; Graphic Card </p>	 
						</div>
						<h4> Estimated Price : &emsp;&emsp;&emsp;&#8377; {price?price:'---'} </h4>
						<div className="build-buttons">
							<button className="build-button" onClick={onEstimateClick}>Get Estimate</button>
							{price?<button className="build-button" onClick={()=>setMode('checkout')}>Place Order</button>:null}
						</div>
						<p className="build-note"> Not satisfied with the choices? <br/>
							We also take custom orders at no extra cost <br/>
							<Link to="/contact" className="build-button">Contact Us</Link>
						</p>
					</div>
				</div>
			</div>
		);
	}
	else if (mode==='checkout')
	{	return (
			<div className="buildpage build-checkout">
				<h3 className="build-title">Confirm your Order</h3>
				<div className="build-flex">
					<div className="build-left">
						{returnSelected()}
						<h4> Price : &emsp;&emsp;&emsp;&#8377; {price?price:'---'} </h4>
					</div>
					<LoginForm title="Enter your Details" error={error}
						b1="Go Back" onb1Click={() => setMode('normal')}
						b2="Confirm" onb2Click={onSendClick} heading = "Please provide landmark with your address">
						<Text label="Name" name="name" value={name} onChange={(event)=>{
							setName(event.target.value) ;
							setError('') ;
						}}/>
						<Text label="E-Mail" name="email" value={email} onChange={(event)=>{
							setEmail(event.target.value) ;
							setError('') ;
						}}/>
						<Text label="Mobile No." name="mobile" value={mobile} onChange={(event)=>{
							setMobile(event.target.value) ;
							setError('') ;
						}}/>
						<TextArea label="Address" name="address" value={address} r={3} c={20} onChange={(event)=>{
							setAddress(event.target.value);
							setError('') ;
						}}/>
					</LoginForm>
				</div>
			</div>
		) ;
	}
	else if (mode === 'error')
	{	return (
			<div className="buildpage"> 
				<h3 className="build-title">Some Error Occured! Please Try Again!</h3>
				<Link to="/" className="build-button">Home</Link>
			</div>
		) ;
	}
	else if(mode === 'success')
	{	return (
			<div className="buildpage"> 
				<h3 className="build-title">Thank you for Placing your Order</h3>
				<p className="category-rd-suit">Your Order has been placed Successfully. You will recieve an E-Mail with your Order Code. <br/><br/>
					Our executive will contact you shortly to explain you about payment mode. Half of the payment would be done in advance and rest of the payment would be done before dispatch of your order.
					<br/><br/>
					Stay Healthy and have a Nice Day.
				</p>
				<Link to="/" className="build-button">Home</Link>
			</div>
		) ;
	}
}

export default BuildPage ;
