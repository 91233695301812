import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom' ;
import CheeseburgerMenu from 'cheeseburger-menu' ;
import HamburgerMenu from 'react-hamburger-menu' ;

import Menu from './Menu.js' ;
import logo from '../../images/logo-hw.webp' ;
import './header.css' ;

const headerItems = [
['Categories', 'category'],
['Build PC', 'build'],
// ['Track Status', 'track'], 
['Contact Us', 'contact']
];

class Header extends Component {
	state = {
	 	color : '' , 
	 	menuOpen: false,
	};

	componentDidMount(){
		window.addEventListener('scroll', this.hideBar);
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.hideBar);
	}

	openMenu = () => {
	    this.setState({ menuOpen: true })
	}

	closeMenu = () => {
	    this.setState({ menuOpen: false })
	}

	hideBar = () => {
	    (window.scrollY>80)? this.setState({ color: 'blue' }):this.setState({ color: '' });
	}

	createHeaderItems = () => {
		return headerItems.map( item => <Link to={item[1]} key={item[1]} className="header-item">{item[0]}</Link>)
	}
 
	checkMobile = () => {
		if(window.screen.availWidth > 923)
		{	return (
				<div className="right-header">
					{this.createHeaderItems()}
					<p className="header-item contact-num"> 
						<a href="tel:+919625162446">+91-96251-62446</a>
						<br/> <a href="tel:+919625104067">+91-96251-04067</a> 
					</p> 
				</div>
			) ;
		}
		else
		{
			return (
				<div className="right-header">
					<CheeseburgerMenu isOpen={this.state.menuOpen} closeCallback={this.closeMenu}>
							<Menu closeCallback={this.closeMenu} checkLoggedIn={this.checkLoggedIn} user={this.props.user}/>
					</CheeseburgerMenu>
					<HamburgerMenu isOpen={this.state.menuOpen} menuClicked={this.openMenu} 
								   width={32} height={24} strokeWidth={8} color='white' 
								   borderRadius={1} animationDuration={0.5} />
				</div>
				) ;
		}
	}

	render(){
		return (
			<div className={"header "+this.state.color}> 
				<Link to="/"><div className="logo">
					<img src = {logo}  alt="myarth-logo" />
				</div></Link>

				{this.checkMobile()}
				
			</div>
		);
	}
}

export default withRouter(Header) ;
