import React  from 'react';

// import { addNotif } from '../notif.js' ;
import { invalidEmail, invalidMobile, isBlank, invalidName } from '../valid.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import Text from '../text/Text.js' ;
import TextArea from '../text/TextArea.js' ;
import './contact.css' ;

class Contact extends React.Component
{	state = {
		error: '',
		name: '',
		mobile: '',
		email: '',
		message: ''
	}

	onSendClick = () => {
		const {name, mobile, message, email, error} = this.state ;
		if(error !== '')
			this.setState({error: 'You cannot proceed without fixing all the errors'});
		else if(invalidName(name))
			this.setState({error: invalidName(name)});
	  	else if(invalidEmail(email))
			this.setState({error: invalidEmail(email)});
		else if(invalidMobile(mobile))
			this.setState({error: invalidMobile(mobile)});
	  	else if(isBlank(message, 'Message'))
			this.setState({error: isBlank(message, 'Message')});
		else
		{	
			// addNotif('Please Wait...') ;

			fetch('https://myarth-api.herokuapp.com/message',{
				method : 'post' ,
				headers : { 'Content-Type' : 'application/json'} ,
				body : JSON.stringify({ name, mobile, email, message }) ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				else
					throw Error(res.statusText) ;
			})
			.then(data => {	
				this.setState({name: '', mobile: '', email: '', message: ''});
				this.props.history.push('') ;
				// addNotif('Successfully Sent Message/Feedback', 'success') ;
			}) 
			.catch( err  => {
				console.log(err) ; 
				// addNotif('There was a problem with sending message', 'error') ;
			}) ;
		}
	}

	onInputChange = (event) => {
		this.setState({	[event.target.name] : event.target.value, error: ''} ) ;
	}

	render() 
	{	const {name, mobile, email, message} = this.state ;
		return(
			<div className = 'contact'>
				<LoginForm title="Bulk Orders / Custom Orders" error={this.state.error}
					b1={this.props.b1} onb1Click={this.props.onb1Click}
					b2="Send &gt;&nbsp;" onb2Click={this.onSendClick} css={this.props.styles} heading = "We will respond as soon as possible">
					<Text label="Name" name="name" value={name} onChange={this.onInputChange}/>
					<Text label="E-Mail" name="email" value={email} onChange={this.onInputChange}/>
					<Text label="Mobile No." name="mobile" value={mobile} onChange={this.onInputChange}/>
					<TextArea label="Query" name="message" value={message} r={3} c={20} onChange={this.onInputChange} />
				</LoginForm>
			</div>
		) ;
	}
}

export default Contact ;
