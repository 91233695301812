import React from 'react' ;
import { Link } from 'react-router-dom' ;

import './hometable.css' ;

let arrs = [['Low','Operating System', 'RAM', 'Memory', 'Processor', 'Graphics'],
	['Low','Windows 10', '4GB', '1TB HDD', 'i3', 'Integrated Graphics'],
	['Normal','Windows 10', '8GB', '1TB HDD/240GB SSD', 'i3', 'Dedicated(Low-End)'],
	['Medium','Windows 10', '16GB', '1TB HDD+240GB SSD', 'i5/Ryzen 5', 'Dedicated(Medium)'],
	['Advanced','Windows 10', '16/32GB', '2TB HDD+480GB SSD', 'i7/Ryzen 7', 'Dedicated(High)']
] ;

let links = ['', '/config?type=Low-End', '/config?type=Normal', '/config?type=Medium', '/config?type=Advanced'] ;

class HomeTable extends React.Component 
{
	returnRowelement = (num) => {
		return arrs[num].map((arr,i) => <p key={i} className={"row row"+i}>{arr}</p>) ;
	}

	returnColumnelement = () => {
		return arrs.map((col, i) => <div key={i} className={"column col"+i}>{this.returnRowelement(i)}<Link className="col-button" to={links[i]}>SELECT</Link></div>) ; 
	}

	render() 
	{
		return (
			<div className="hometable">
				{this.returnColumnelement()}
			</div>
		) ;
	}
}

export default HomeTable ;