import React from 'react';

import HomeTable from '../../hometable/HomeTable.js' ;
import './home2.css' ;

import stud from '../../images/comp_child.webp' ;
import coder from '../../images/development.webp' ;
import gamer from '../../images/gaming.webp' ;
import graphic from '../../images/graphics.webp' ;
import off from '../../images/office.webp' ;
import stream from '../../images/streaming.webp' ;
import cabinet from '../../images/cabinet.webp' ;

const works = [ 
	{ image: stud, text: 'Students', link: '/config?type=Normal'}, 
	{ image: gamer, text: 'Gaming', link: '/config?type=Advanced'}, 
	{ image: coder, text: 'Development', link: '/config?type=Normal'}, 
	{ image: off, text: 'Office Work', link: '/config?type=Low-End'},	
	{ image: stream, text: 'Streaming', link: '/config?type=Medium'}, 
	{ image: graphic, text: 'Designing', link: '/config?type=Medium'}
] ;

const HomeSecond = ({history}) => {
	const returnChoices = () => {
		return works.map( work => {
			return (
				<div key={work.text} className="work-con" onClick={() => history.push(work.link)} style={{backgroundImage: 'url('+work.image+')'}}>
					<p> {work.text} </p>
				</div>
			) ;
		}) ;
	}

	return (
		<div className="home-second">
			<h3 className="home2-title"> Start by Selecting your Choice </h3>
			<p className="home-second-p"><span className="home2-no">1</span> Start by selecting work category :</p>
			<div className="choices-con"> {returnChoices()} </div>
			<p className="home-second-p"><span className="home2-no">2</span> Start by selecting specs:</p>
			<p className="offer shake-horizontal"><span className="offer-head">LIMITED OFFER :</span> Get keyboard & mouse free with every CPU</p>
			<div className="table-img">
				<HomeTable/>
				<img className="home-cpu" src = {cabinet} alt='cpu' />
			</div>
			<p className="home2-note"><strong>NOTE :</strong> You can change individual components after selecting category</p>
			</div>
	);
}

export default HomeSecond ;