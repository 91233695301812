import React, { Component } from 'react';
import { Link } from 'react-router-dom' ;

import cpu from '../../../images/cpu.webp' ;
import './home4.css' ;

class HomeFourth extends Component {
	render() {
		return (
			<div className="home4">
				<div className="home4-left">
						<img className="cpu" src={cpu} alt="CPU Components" />
				</div>
				<div className="home4-right">
					<h3 className="home2-title"> No Category suits you? </h3>
					<h4 className="home4-text"> Build your customised PC that<br/> suits your needs perfectly </h4>
					<Link className="home4-btn" to="/build"> Start Building </Link>
				</div>
			</div>
		);
	}
}

export default HomeFourth ;