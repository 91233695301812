import React from 'react';

import Category from './Category.js' ;
import './categorypage.css' ;

import low from '../images/low.jpg' ;
import high from '../images/high.jpg' ;
import normal from '../images/medium.jpg' ;
import medium from '../images/medium-high.jpg' ;
 
let datas = [
	{	image : low ,
		title : 'Low-End Build' ,
		suitable: ' Routine Home Use, Routine Office Work(Word, PPT, Excel), Songs, Movies, Surfing the internet' ,
		price : '29199' ,
		mrp: '35999' ,
		specs : [
			["Operating System","Windows 10"] , 
			["RAM","4 GB"] , 
			["Processor","Intel Pentium Gold"] , 
			["Memory","1 TB HDD"] , 
			["MotherBoard","Gigabyte H310"] ,
			["Cabinet","I-ball"] , 
			["Graphics","Integrated"] , 
			["Monitor","HP 1366X768"] ,
			["UPS","Intex"] 
		] ,	
		offer : 'Free Keyboard & Mouse with every purchase'
	} ,
	{	image : normal ,
		title : 'Normal Build' ,
		suitable: 'Routine Tasks that require little processing, like Programming, Web Development, Online Classes' ,
		price : '38599' ,
		mrp : '44999' ,
		specs : [
			["Operating System","Windows 10"] , 
			["RAM","8 GB"] , 
			["Processor","Intel i3 9th Gen"] , 
			["Memory","1 TB HDD"] , 
			["MotherBoard","Gigabyte H310"] , 
			["Cabinet","Intex"] , 
			["Graphics","Nvidia GeForce GT710"] , 
			["Monitor","HP 1366X768"] ,
			["UPS","Microtek"] 
		] ,	
		offer : 'Free Keyboard & Mouse with every purchase'
	} ,
	{	image : medium ,
		title : 'Medium Build' ,
		suitable: 'Gaming, Streaming, Android development, Heavy Programming, Image editing and similar uses' ,
		price : '62599' ,
		mrp: '73999' ,
		specs : [
			["Operating System","Windows 10"] , 
			["RAM","16 GB"] , 
			["Processor","Intel i5 9th Gen"] , 
			["Memory","1 TB HDD + 240 GB SSD"] , 
			["MotherBoard","Gigabyte H310"] , 
			["Cabinet","Intex"] , 
			["Graphics","Nvidia GEforce GTX1050"] , 
			["Monitor","HP 1366X768"] ,
			["UPS","Microtek"] 
		] ,	
		offer : 'Free Keyboard & Mouse with every purchase'
	} ,
	{	image : high ,
		title : 'Advanced Build' ,
		suitable: 'Advanced Gaming & Graphics, Video Editing, Streaming, Data Analytics, Research & Machine Learning, AI' ,
		price : '105799' ,
		mrp: '130399' ,
		specs : [
			["Operating System","Windows 10"] , 
			["RAM","32 GB"] , 
			["Processor","Intel i7 9th Gen"] , 
			["Memory","2 TB HDD + 480 GB SSD"] , 
			["MotherBoard","Gigabyte H310"] , 
			["Cabinet","Zebronics Zeb (LED)"] , 
			["Graphics","Nvidia GEforce GTX1650"] , 
			["Monitor","HP 1920X1080"] ,
			["UPS","Microtek"] 
		] ,	
		offer : 'Free LED Keyboard, Mouse & UPS with every purchase'
	} ,
] ;

const returnCategory = () => {
	return datas.map((data,i) => <Category key={i} data = {data} />) ;	
}

const CategoryPage = () => {
	return (
		<div className="categorypage">
			{returnCategory()}
		</div>
	);
}

export default CategoryPage ;