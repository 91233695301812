import React, { Component } from 'react';
import { Link } from 'react-router-dom' ;

import Slider from '../slider/Slider.js' ;
import HomeSecond from './homesecond/HomeSecond.js' ;
import HomeThird from './homethird/HomeThird.js' ;
import HomeFourth from './homefourth/HomeFourth.js' ;

import cpu from '../../images/cpu.webp' ;
import i1 from '../images/cpu-group.webp' ;
import i2 from '../images/logos2.webp' ;
import i3 from '../images/study-computer.webp';
import i4 from '../images/streaming-computer.webp' ;
import i5 from '../images/office-computers.webp' ;
import i6 from '../images/school-lab.webp' ;
import i7 from '../images/home-computer.webp' ;

import './home.css' ;

const sliderData = [
	{
		image : i1 ,
		description: "Computers for everyone's specific needs"
	} ,
	{
		image : i2 ,
		description: 'Computer parts only from respected brands'
	} ,
	{
		image : i3 ,
		description: 'Computer that becomes your study partner'
	} ,
	{
		image : i4 ,
		description: 'Your dream streaming setup can now be reality'
	} ,
	{
		image : i5 ,
		description: 'Customised computers that your office needs'
	} ,
	{
		image : i6 ,
		description: 'Bulk ordering also available'
	} ,
	{
		image : i7 ,
		description: 'Best system for routine home usage'
	} 
] ;

class Home extends Component {
	render() {
		// console.log(this.props.history) ;
		return (
			<div className="home-con">
				<div className="home">
					<div className="home-left">
						<h3 className="home-slogan">Build a computer that helps you achieve your dreams</h3>
						<img className="cpu" src={cpu} alt="CPU Components" />
						<Link className="home-gs" to="/category"> Get Started </Link>
					</div>
					<div className="home-right">
						<Slider width="100" color="#E70013" arr={sliderData} autoplay="on" interval={9000}/>
					</div>
				</div>
				<HomeSecond history = {this.props.history} />
				<HomeThird />
				<HomeFourth />
			</div>
		);
	}
}

export default Home ;
