import React from 'react' ;
import { Link } from 'react-router-dom' ;

const returnSpecs = (data) => {
	return data.map((one,i) => <p className="category-specs" key={i}><strong>{one[0]} </strong>: {one[1]}</p>) ;
}

const Category = ({data}) => { 	
	const { image, title, offer, price, suitable, specs, mrp} =  data ;
	return(
		<div className="category">
			<div className="category-left">
				<h3 className="category-title">{title}</h3>
				<div className="category-disp">{returnSpecs(specs)}</div>
				<p className="category-offer">{offer}</p>
				<p className="category-price">
					<span className="mrp-cost"> M.R.P Cost: </span>
					<span className="kata-hua"> &#8377;{mrp} </span> &nbsp;
					<strong>Our Estimated Cost</strong> : &#8377;{price}
				</p>
				<Link className="category-left-btn" to={"/config?type="+title.split(' ')[0]}>
				Configure</Link>
				<p className="category-warn">Note : You can remove or modify the components while config</p>
			</div>
			<div className="category-right">
				<div className="category-right-up">
					<img className="category-img" src={image} alt={title} />
				</div>
				<p className="category-warn">Warning : Image is only for representation, actual product may vary.</p>
				<div className="category-right-down">
					<p className="category-rd-suit">Suitable For : <br/>{suitable}</p>
				</div>
			</div>
		</div>
	) ;
}

export default Category ;