import React from 'react';
import { faShippingFast, faTools, faFileInvoice, faUserCheck, faShieldAlt, faHandHoldingUsd, faDesktop, faUsers } from '@fortawesome/free-solid-svg-icons' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;

import './home3.css' ;

const icons = [
[faShippingFast, 'All-India Fast Shipping'], [faTools, 'Assembled in India'],
[faDesktop, 'New & Original Parts'], [faUserCheck, 'Customer Trusted'],
[faFileInvoice, 'Manufacturer Warranty'], [faHandHoldingUsd, 'Save Money'],
[faShieldAlt, 'Tried and Tested'], [faUsers, 'User Oriented']
] ;

const returnFeatures = () => {
	return icons.map(ico => {
		return ( 
			<div key={ico[1]} className="icon-con">
				<FontAwesomeIcon icon={ico[0]} />
				<p>{ico[1]}</p>
			</div>
		 );
	}) ;	
}

const HomeThird = () => {
	return (
		<div className="home-third">
			{returnFeatures()}
		</div>
	);
}

export default HomeThird ;