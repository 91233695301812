import React from 'react';

import BuildPage from '../buildPage/buildPage.js' ;
import './config.css' ;

const initObj = {
	'Low-End': {
		RAM: 'Crucial 4GB RAM',
		HardDisk: 'Western Digital 1TB Internal HDD',
		SSD: 'Crucial 120GB SSD Memory',
		MotherBoard: 'GigaByte H310 (For Intel 9th gen)',
		Processor: 'Intel Pentium Gold',
		GPU: 'Nvidia GeForce GT710',
		Display: 'HP 1366x768 Monitor',
		Cabinet: 'I-ball (w/ 450W SMPS)',
		UPS: 'Intex 725',
		OS: 'Microsoft Windows 10'
	},
	Normal: {
		RAM: 'Crucial 8GB RAM',
		HardDisk: 'Western Digital 1TB Internal HDD',
		SSD: 'Crucial 120GB SSD Memory',
		MotherBoard: 'GigaByte H310 (For Intel 9th gen)',
		Processor: 'Intel i3-9th Gen',
		GPU: 'Nvidia GeForce GT710',
		Display: 'HP 1366x768 Monitor',
		Cabinet: 'Intex (w/ 450W SMPS)',
		UPS: 'Microtek 625',
		OS: 'Microsoft Windows 10'
	},
	Medium: {
		RAM: 'Crucial 16GB RAM',
		HardDisk: 'Western Digital 1TB Internal HDD',
		SSD: 'Crucial 240GB SSD Memory',
		MotherBoard: 'GigaByte H310 (For Intel 9th gen)',
		Processor: 'Intel i5-9th Gen',
		GPU: 'Nvidia GeForce GTX1050',
		Display: 'HP 1366x768 Monitor',
		Cabinet: 'Intex (w/ 450W SMPS)',
		UPS: 'Microtek 625',
		OS: 'Microsoft Windows 10'
	},
	Advanced: {
		RAM: 'Crucial 32GB RAM',
		HardDisk: 'Western Digital 2TB Internal HDD',
		SSD: 'Crucial 480GB SSD Memory',
		MotherBoard: 'GigaByte H310 (For Intel 9th gen)',
		Processor: 'Intel i7-9th Gen',
		GPU: 'Nvidia GeForce GTX1650',
		Display: 'HP 1920x1080 Monitor',
		Cabinet: 'Zebronics Zeb LED(w/ 450W SMPS)',
		UPS: 'Microtek 625',
		OS: 'Microsoft Windows 10'
	}
} ;

const checkObj = {
	'Low-End': {
		Display: false,
		UPS: false,
		SSD: true,
		GPU: true
	},
	Normal: {
		Display: false,
		UPS: false,
		SSD: true,
		GPU: false
	},
	Medium: {
		Display: false,
		UPS: false,
		SSD: false,
		GPU: false
	},
	Advanced: {
		Display: false,
		UPS: false,
		SSD: false,
		GPU: false
	}
}

const Config = () => <BuildPage type={window.location.search.slice(1,).split('=')[0]} obj1={initObj[window.location.search.slice(1,).split('=')[1]]} obj2={checkObj[window.location.search.slice(1,).split('=')[1]]} /> ;

export default Config ;