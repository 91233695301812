import React, { Component } from 'react';
import AnimateOnChange from 'react-animate-on-change' ;

import './slider.css' ;

class Slider extends Component {
	
	state = {
		page: 0,
	}

	componentDidMount() 
	{	const interval = (this.props.interval)?this.props.interval:5000 ;
		if(this.props.autoplay === 'on')
			this.countdown = setInterval(this.onNextClick, interval);
	}

  	componentWillUnmount() {
    	if(this.props.autoplay === 'on')
			clearInterval(this.countdown);
  	}

	createDots = () => {
		return this.props.arr.map( (im,i) => <div key={i} className="slider-dot" style={{ backgroundColor: this.checkColor(i)}} onClick={() => this.setPage(i)}/> ) ;
	}

	checkColor = (num) => {
		if(num === this.state.page)
			return this.props.color ;
		else
			return '#ccc' ;
	}

	setPage = (num) => {
		this.setState({page: num}) ;
	}

	onNextClick = () => {
		let nextPage = (this.state.page+1)%this.props.arr.length ;
		this.setState({page: nextPage}) ;
	}

	generatePage = () => {
		const {image , description } = this.props.arr[this.state.page] ;
		return ( 
			<AnimateOnChange baseClassName="slider-page" animationClassName="fade-in" 
						 animate={this.state.page !== -1}>
					<img src={image} alt={description} />
					<span> {description} </span>
			</AnimateOnChange>
		) ;
	}

	render() {
		return (
			<div className="slider-cont" style={ {width: this.props.width + '%'} }>
				{this.generatePage()}

				<div className="slide-nav">	{this.createDots()} </div>
			</div>
		);
	}
}

export default Slider ;