import React,{ lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom' ;

import Home from './comps/home/Home.js' ;
import Header from './comps/header/Header.js' ;
import NotFound from './comps/home/NotFound.js' ;
import CategoryPage from './comps/categoryPage/CategoryPage.js' ;
import BuildPage from './comps/buildPage/buildPage.js' ;
import Config from './comps/config/Config.js' ;
import Footer from './comps/footer/Footer.js' ;
import Contact from './comps/contact/Contact.js' ;
import {RefundPolicy, ShippingPolicy} from './comps/footer/refundPolicy/RefundPolicy.js' ;

const PrivacyPolicy = lazy(() => import('./comps/footer/privacyPolicy/PrivacyPolicy.js'));
const TermsCondition = lazy(() => import('./comps/footer/termsCondition/TermsCondition.js'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class App extends React.Component {

	resize = () => this.forceUpdate() ;

	componentDidMount() {
	  window.addEventListener('resize', this.resize) ;
	}

	componentWillUnmount() {
	  window.removeEventListener('resize', this.resize) ;
	}

	render() {
		return (
		  	<div className="App">
        		<BrowserRouter>
        		  <ScrollToTop />
		          <div>
		          	<Header />
		            <Switch>
				        <Route path='/' exact component={Home} />
				        <Route path='/category' exact component={CategoryPage} />
				        <Route path='/build' key="build" exact component={BuildPage} />
				        <Route path='/config' key="config" exact render={Config} />
				        <Route path='/contact' exact render={props=><Contact {...props} styles="contact-form"/>}/>
				        <Route path='/privacy-policy' exact render={props=><Suspense fallback={<div> Loading... </div>}><PrivacyPolicy/></Suspense>}/>
			            <Route path='/terms-condition' exact render={props=><Suspense fallback={<div> Loading... </div>}><TermsCondition/></Suspense>}/>
			            <Route path='/refund-policy' exact render={props=><Suspense fallback={<div> Loading... </div>}><RefundPolicy/></Suspense>}/>
			            <Route path='/shipping-policy' exact render={props=><Suspense fallback={<div> Loading... </div>}><ShippingPolicy/></Suspense>}/>
			        	<Route exact component={NotFound} />
			        </Switch>
			        <Footer />
		          </div>
		        </BrowserRouter>
      		</div>
		);
	}
}

export default App;