import React from 'react' ;
import { faPhoneAlt, faEnvelope, faMap} from '@fortawesome/free-solid-svg-icons' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { Link } from'react-router-dom' ;
import { withRouter } from 'react-router' ;

import logo_img from '../../images/logo-black.webp' ;

import './footer.css' ;

const Address = () => {
	return (
		<div className = 'address'>
			<h3 className="contact-info">Contact Information</h3>
			<div>
				<p><FontAwesomeIcon icon = {faMap} />&nbsp;Unit No. 4, First Floor, CSC, Pocket B & C, Phase - 4, Ashok Vihar, Delhi - 110052.</p>
				<p><FontAwesomeIcon icon = {faPhoneAlt} />&nbsp;+91-96251-62446, +91-96251-04067</p>
				<p><FontAwesomeIcon icon = {faEnvelope} />&nbsp;myarth.tech@gmail.com</p>
			</div>
			<div className="footer-links">
				<Link to="/privacy-policy"> Privacy Policy </Link>
				<p> | </p>
				<Link to="/refund-policy"> Refund Policy </Link>
				<p> | </p>
				<Link to="/shipping-policy"> Shipping Policy </Link>
				<p> | </p>
				<Link to="/terms-condition"> Terms & Conditions </Link>
			</div>
		</div>
	) ;
}

const Footer = () => {
	// const width = (window.screen.availWidth > 923)?'600':'350'
	return(
		<div className = "footer-main" id="footer">
			<div className="about-us">
				<div className="about-top">
					<h3 className="contact-info">About Us</h3>
					<img className="logo-myarth" src={logo_img} alt ="Myarth" />
				</div>
				<p><strong>Myarth Hardware</strong> is a part of <strong>Myarth</strong>, an organisation based in New Delhi, working in the IT sector. Myarth aims at bringing technology of tomorrow in the hands of today&apos;s people.</p>
				<p><strong>Myarth Hardware</strong> wants to bring affordable computers within the reach of anyone who wishes to use any kind of computer. From a student who wants to study and make documents to the aspiring E-sport player of India, We have a solution for Everyone.</p>
			</div>
			<div>
				<Address />
			</div>
		</div>
	) ;
}

export default withRouter(Footer) ;
