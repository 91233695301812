import React from 'react';

import DisplayDetailed from '../../display/DisplayDetailed.js' ;

const refund = [
'50% of the total amount is paid as advance while placing the order and rest should be paid at the time order dispatch.',
'All order can be cancelled before full payment',
'Refund of the advance amount is subject to time interval between placement and cancellation of order, if cancellation is done within 72 hours of order placement, full advance amount will be refunded.',
'If cancellation is done after 72 hours of order placement but before full payment, 50% of the advance amount will be refunded.',
'Once full Payment is made and order has been dispatched no provision of refund or cancellation is available.',
'htmlFor any kind of grievance or queries related to Payments / Refund / Cancellation, write to us at: <b>myarth.tech@gmail.com</b>'
] ;

const shipping = [
'All deliveries would be done through courier services like eKart, DTDC, etc.',
'Normally, shipping takes upto 4 working days(for Delhi NCR). For Other parts of India, delivery time is subject to courier services and may take upto 15 working days (for remote areas of India)',
'All deliveries will be made only in India, no delivery outside India is provided.',
'If goods are damaged during courier transit, it is not the responsibility of Myarth Hardware to provide Refund/Replacement'
] ;

const RefundPolicy = () => {
	return (
		<div>
			<DisplayDetailed title="Myarth Hardware Refund/Cancellation Policy" lidata={refund} />
		</div>
	);
}

const ShippingPolicy = () => {
	return (
		<div>
			<DisplayDetailed title="Myarth Hardware Shipping Policy" lidata={shipping} />
		</div>
	);
}

export { RefundPolicy, ShippingPolicy} ;